const reactionImg = {
  like: "https://raw.githubusercontent.com/napthedev/fireverse/c1233e40a2c52b21690ee6dbaadeda7185a76b5f/public/reactions-icon/like.svg",
  love: "https://raw.githubusercontent.com/napthedev/fireverse/c1233e40a2c52b21690ee6dbaadeda7185a76b5f/public/reactions-icon/love.svg",
  haha: "https://raw.githubusercontent.com/napthedev/fireverse/c1233e40a2c52b21690ee6dbaadeda7185a76b5f/public/reactions-icon/haha.svg",
  care: "https://raw.githubusercontent.com/napthedev/fireverse/c1233e40a2c52b21690ee6dbaadeda7185a76b5f/public/reactions-icon/care.svg",
  angry:
    "https://raw.githubusercontent.com/napthedev/fireverse/c1233e40a2c52b21690ee6dbaadeda7185a76b5f/public/reactions-icon/angry.svg",
  sad: "https://raw.githubusercontent.com/napthedev/fireverse/c1233e40a2c52b21690ee6dbaadeda7185a76b5f/public/reactions-icon/sad.svg",
  wow: "https://raw.githubusercontent.com/napthedev/fireverse/c1233e40a2c52b21690ee6dbaadeda7185a76b5f/public/reactions-icon/wow.svg",
};

export default reactionImg;
